import { generatePath } from 'react-router';
import ExternalLink from 'components/typography/ExternalLink';
import Typo from 'components/typography/Typo';
import { s3BucketsSteps } from 'layouts/AuthorizedWithLeftMenu/Breadcrumbs';
import Header from 'layouts/AuthorizedWithLeftMenu/Header';
import { S3Sample } from 'models/s3Sample/dto';
import { PATHS } from 'services/router';
import S3BucketHeaderStyles from 'views/SuperAssets/S3Buckets/S3BucketItemView/S3BucketHeader/index.module.pcss';

type Props = {
	sample: Pick<
		S3Sample,
		| 'aws_console_url'
		| 'bucket_id'
		| 'bucket_name'
		| 'cluster_id'
		| 'cluster_name'
		| 'name'
		| 'type'
	> | null;
};

function S3SampleHeader({ sample }: Props) {
	if (!sample) return null;

	const { aws_console_url, bucket_id, bucket_name, cluster_id, cluster_name, name, type } = sample;
	const showSensitiveSwitcher = type === 'csv' || type === 'spreadsheet';

	return (
		<Header
			showSensitiveSwitcher={showSensitiveSwitcher}
			breadcrumbProps={{
				steps: [
					...s3BucketsSteps,
					{
						path: generatePath(PATHS.S3_BUCKETS_ITEM, {
							id: bucket_id,
							tab: 'summary',
						}),
						name: bucket_name,
					},
					{
						path: generatePath(PATHS.S3_BUCKETS_ITEM, {
							id: bucket_id,
							tab: 'files',
						}),
						name: 'Files',
					},
					{
						path: generatePath(PATHS.S3_CLUSTER_ITEM, {
							id: bucket_id,
							clusterId: cluster_id,
						}),
						name: cluster_name,
					},
				],
				finalStep: name,
			}}
			titleBlock={
				<div className={S3BucketHeaderStyles.headerContainer}>
					<div>
						<Typo
							variant="D/Medium/H400-Page-Title"
							dataTest="s3-sample-header-name"
							className={S3BucketHeaderStyles.ellipsisText}
						>
							{name}
						</Typo>
					</div>

					<ExternalLink href={aws_console_url} className={S3BucketHeaderStyles.link}>
						View in cloud console
					</ExternalLink>
				</div>
			}
		/>
	);
}

export { S3SampleHeader };
