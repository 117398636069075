import { TSensitivity, TOrder } from 'models/common/dto';
import { DataTypeItem, DataTypeRequest } from 'models/dataTypes/dto';
import { S3BucketItem } from 'models/s3Buckets/dto';

type FileType =
	| 'csv'
	| 'json'
	| 'yaml'
	| 'log'
	| 'plain_text'
	| 'spreadsheet'
	| 'document'
	| 'video'
	| 'audio'
	| 'image'
	| 'parquet'
	| 'other'
	| 'unknown';

const fileTypeLabels: Record<FileType, string> = {
	csv: 'CSV',
	json: 'JSON',
	yaml: 'YAML',
	log: 'LOG',
	plain_text: 'TXT',
	spreadsheet: 'XLS',
	document: 'Document',
	video: 'Video',
	audio: 'Audio',
	image: 'Image',
	parquet: 'Parquet',
	other: 'Multiple',
	unknown: 'Unknown',
};

type ClusterCommonFields = {
	id: number;
	name: string;
	path: string;
	real_name_sample: string;
	size: number;
	objects_count: number;
	is_analyzable: boolean;
	data_types: DataTypeItem['id'][];
	sensitivity: TSensitivity;
	last_scan_at: number;
	aws_console_url: string;
	has_more_clusters?: boolean;
};

type ClusterDirectory = ClusterCommonFields & {
	type: 'directory';
	clusters: ClusterFile[];
};

type ClusterFile = ClusterCommonFields & {
	type: FileType;
};

type S3ClusterItem = ClusterDirectory | ClusterFile;

type S3ClusterList = {
	clusters: S3ClusterItem[];
	total: number;
};

type S3ClusterTableParams = {
	id: number;
	limit: number;
	offset: number;
	'data-types': DataTypeRequest;
	sort: {
		orderBy: keyof S3ClusterItem;
		order: TOrder;
	};
};

type S3ClusterSublist = {
	has_more_clusters?: boolean;
	clusters: ClusterFile[];
};

type ClusterFileSample = {
	id: number;
	name: string;
	path: string;
	size: number;
	estimated_rows: number;
	data_types: DataTypeItem['id'][];
	created_at: number;
	last_scan_at: number;
	aws_console_url: string;
	status: string;
};

type ClusterFileSampleTableParams = {
	id: number;
	'data-types': DataTypeRequest;
	sort: {
		orderBy: keyof ClusterFileSample;
		order: 'asc' | 'desc';
	};
};

type S3ClusterFileWithSamples = {
	id: number;
	type: FileType;
	path: string;
	real_name_sample: string;
	name: string;
	size: number;
	objects_count: number;
	estimated_rows: number;
	owner: S3BucketItem['owner'];
	sensitivity: TSensitivity;
	data_types: DataTypeItem['id'][];
	last_scan_at: number;
	aws_console_url: string;
	samples: ClusterFileSample[];
};

export type {
	FileType,
	ClusterDirectory,
	ClusterFile,
	ClusterFileSample,
	ClusterFileSampleTableParams,
	S3ClusterList,
	S3ClusterTableParams,
	S3ClusterSublist,
	S3ClusterItem,
	S3ClusterFileWithSamples,
};

export { fileTypeLabels };
