import { S3BucketItem, S3BucketList, S3BucketStatus, S3BucketDataFlowsList } from './dto';

let exampleId = 1;

const s3BucketItemsExample: S3BucketItem[] = [
	{
		id: exampleId++,
		name: 's3bucket-1',
		owner: 'owner',
		description: 'bucket description',
		account_id: '1',
		region: 'us-west-1',
		aws_console_url: 'http://...',
		created_at: 1234567890,
		last_checked: 1234567890,
		tags: [{ key: 'key', value: 'value' }],
		size: 10000,
		objects_count: 1,
		is_encrypted: false,
		is_public: true,
		sensitivity: 'Low',
		data_types: [3, 5, 7],
	},
	{
		id: exampleId++,
		name: 's3bucket-2',
		owner: 'owner',
		description: 'bucket description 2',
		account_id: '2',
		region: 'us-west-2',
		aws_console_url: '',
		created_at: 1234567891,
		last_checked: 1234567891,
		tags: [{ key: 'key', value: 'value' }],
		size: 10000,
		objects_count: 1,
		is_encrypted: false,
		is_public: false,
		sensitivity: 'Medium',
		data_types: [2],
	},
];

const s3BucketListExample: S3BucketList = {
	total: 10,
	buckets: s3BucketItemsExample,
	// total_filtered: 2, // Optional - so should not be in example, otherwise errors will be triggered.
};

const s3BucketStatusExample: S3BucketStatus = {
	id: 1,
	objects_count: 10,
	asset_flows_count: 2,
	is_scanned: true,
	progress: [
		{
			type: 'csv',
			is_analyzable: true,
			progress: 90,
		},
		{
			type: 'image',
			is_analyzable: false,
			progress: 0,
		},
		{
			type: 'other',
			is_analyzable: false,
			progress: 0,
		},
	],
};

const s3BucketDataFlowsListExample: S3BucketDataFlowsList = {
	dataflows: [
		{
			data_types: [4, 5],
			flow_direction: 'none',
			interacts_with: 26089887,
			last_seen: 1712572950555,
			sensitivity: 'N/A',
			type: 'asset',
			is_encrypted: false,
			is_mesh_network: false,
		},
	],
	total: 1,
};

export {
	s3BucketItemsExample,
	s3BucketListExample,
	s3BucketStatusExample,
	s3BucketDataFlowsListExample,
};
